.App {
  max-width: 100%;
  height: 100vh;
  // overflow-y: hidden;
}
.chat-list{
  border-radius: 12px;
  border: 2px solid #F1F1F1;
   overflow-y: hidden;
  // position: relative;
  height: calc(100dvh - 210px);
}
.chat-active {
  .chat-list {
    display: block;
  }
}