.EditProfile {
    width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 35px;
    padding: 50px;

    &__Title {
        font-weight: 700;
        font-size: 32px;
    }

    &__ImageSection {
        display: flex;
        flex-direction: column;
        gap: 15px;

        img {
            border: 1px solid rgba(128, 128, 128, 0.274);
            width: 177px;
            height: 177px;
            border-radius: 50%;
            object-fit: cover;
        }

        &__UploadButton {

            span {
                display: flex;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                border: 1px solid #000;
                background: #FAFAFA;
            }
        }
    }

    &__Inputs {
        display: flex;
        gap: 25px;
    }

    &__Buttons {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }

    &__AboutInput {
        width: 100%;
    }
}