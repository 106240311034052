.ClientProjectsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    gap: 50px;

    &__Header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__Title {
            font-size: 40px;
            font-weight: 700;
        }

        &__Actions {
            display: flex;
            align-items: center;
            gap: 35px;
        }
    }

    &__Body {
        width: 100%;
        border-radius: 12px;
        border: 2px solid #F1F1F1;
        overflow-y: auto;
        min-height: 350px;
        max-height: calc(100dvh - 210px);
    }

}