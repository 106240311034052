.DashboardLayout {
    display: flex;

    &__Sidebar {
        width: 100%;
        max-width: 350px;
        position: fixed;
        overflow-y: auto;
    }

    &__MainSection {
        margin-left: auto;
        width: calc(100% - 360px);
        overflow: auto;
        //height: 100dvh;
    }
}