.SidebarContainer {
    height: 100dvh;
    overflow: auto;
    display: flex;
    padding: 20px 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    background: black;
    color: white;

    &__UpperSection {
        width: 100%;
        &__Header {
            display: flex;
            flex-direction: column;
            gap: 50px;

            &__UserInfo {
                display: flex;
                padding: 10px;
                gap: 15px;
                border-radius: 10px;
                background: #FFF;
                height: 74px;
                align-items: center;

                &__Image {
                    width: 54px;
                    height: 54px;
                    border-radius: 7px;
                }

                &__Name {
                    text-transform: capitalize;
                    color: #000;
                    font-weight: 500;
                    font-size: 22px;
                }
            }
        }
        &__Divider {
            background: rgba(255, 255, 255, 0.25);
            height: 2px;
        }

        &__MenuOptions {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &__Option {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 15px;
                text-decoration: none;
                color: white;
                padding: 5px;

                &__Selected {
                    border-radius: 10px;
                    border: 1px solid #FFF;
                    background: rgba(255, 255, 255, 0.10);
                }
            }
        }
    }

    &__LowerSection {
        display: flex;
        gap: 15px;
        padding: 10px;
        align-items: center;
        cursor: pointer;
    }
}