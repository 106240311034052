/* Base Styling */
.a4-page {
  //width: 210mm; // A4 width
  //height: 297mm; // A4 height
  width: 794px;
  /* A4 width in pixels */
  height: 1120px;
  /* A4 height in pixels */
  margin: 0; // Remove default margin
  padding: 0;

  .a4-content {
    width: 100%;
    height: 100%;
    padding: 20mm; // Add some padding for content
  }
}

/* Responsive Styling (example) */
@media (max-width: 640px) {

  // Small screens
  .a4-page {
    width: 80%;
    height: auto;
  }

  .a4-content {
    padding: 10px;
  }
}

/* Print Specific Styling */
@media print {
  .a4-page {
    width: 210mm;
    height: 297mm;
    margin: 0;
    padding: 0;
  }

  .a4-content {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

.editor-toolbar {
  position: sticky !important;
  top: 0 !important;
  background: #fff !important;
  z-index: 1;
  border-bottom: 1px solid black !important;

}