.FreelancerTile {
    width: 100%;
    display: flex;
    padding: 30px;
    flex-direction: column;
    gap: 25px;
    border-bottom: 2px solid #F1F1F1;

    &__FirstRow {
        display: flex;
        justify-content: space-between;

        &__UserInfo {
            display: flex;
            gap: 15px;
            justify-content: center;
            align-items: center;

            &__Image {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }

            &__Details {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &__Name {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    &__Rating {
                        display: flex;
                        padding: 5px 10px;
                        align-items: center;
                        gap: 5px;
                        border-radius: 10px;
                        background: rgba(0, 0, 0, 0.05);
                    }
                }

                &__ProfessionalTitle {
                    opacity: 0.6;
                }
            }
        }

        &__Actions {
            display: flex;
            gap: 15px;
        }
    }

    &__SecondRow {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        row-gap: 15px;

        &__JobTitle {
            padding: 10px 15px;
            border-radius: 10px;
            border: 1px solid #DCDCDC;
        }
    }
}