.FreelancerApplicationsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    gap: 50px;

    &__Header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__Title {
            font-size: 40px;
            font-weight: 700;
        }

        &__Actions {
            display: flex;
            align-items: center;
            gap: 35px;
        }
    }

    &__Body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 35px;

        &__Filters {
            display: flex;
            gap: 35px;
        }
        
        &__ApplicationsList {
            border-radius: 12px;
            border: 2px solid #F1F1F1;
            overflow-y: scroll;
            height: 600px;
        }
        &__ChatList {
            border-radius: 12px;
            border: 2px solid #F1F1F1;
             overflow-y: hidden;
            // position: relative;
            height: 530px;
        }
    
    }

}