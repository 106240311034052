.Discover {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    gap: 30px;

    &__Header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__Title {
            font-size: 40px;
            font-weight: 700;
        }

        &__Actions {
            display: flex;
            align-items: center;
            gap: 35px;
        }
    }

    &__Body {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__Filters {
            width: 25%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            border-radius: 15px;
            border: 2px solid #6A57E3;
            &__Skills, &__Tools {
                :where(.css-dev-only-do-not-override-d2lrxs).ant-select-outlined.ant-select-multiple .ant-select-selection-item {
                    background: linear-gradient(274deg, #6A57E3, #C26DEA);
                    border: 1px solid transparent;
                    height: 30px;
                    color: white;
                    display: flex;
                    gap: 15px;
                    align-items: center;
                  }
                
                  .anticon >* {
                    color: white;
                }
            }

            &__Rate {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &__Inputs {
                    display: flex;
                    gap: 10px;
                }
            }
        }

        &__MainSection {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 35px;

            &__AdditionalFilters {
                display: flex;
                gap: 35px;

                .ant-segmented {
                    border-radius: 15px;
                    border: 1px solid #D0D0D0;
                    background: #FAFAFA;
                }

                .ant-segmented-item:hover {
                    border-radius: 15px;
                }

                .ant-segmented-item-selected {
                    border-radius: 15px;
                    background: #000;
                    color: #fff;
                }
            }

            &__SearchResults {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                border-radius: 12px;
                border: 2px solid #F1F1F1;
            }
        }
    }
}