.FreelancerOverviewContainer {
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__FirstRow {
        display: flex;
        gap: 15px;
        align-items: center;

        &__Image {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }

        &__Details {
            display: flex;
            flex-direction: column;
            gap: 5px;

            &__Name {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                &__Rating {
                    display: flex;
                    padding: 5px 10px;
                    align-items: center;
                    gap: 5px;
                    border-radius: 10px;
                    background: rgba(0, 0, 0, 0.05);
                }
            }

            &__ProfessionalTitle {
                opacity: 0.6;
            }
        }
    }

    &__SecondRow {
        font-size: 16px;
    }

    &__ThirdRow {
        display: flex;
        padding: 25px;
        flex-direction: column;
        gap: 10px;
        border-radius: 20px;
        border: 1px solid #C8C8C8;
        background: #FFF;
        box-shadow: 66px 704px 198px 0px rgba(142, 142, 142, 0.00), 42px 451px 181px 0px rgba(196, 196, 196, 0.01), 24px 253px 153px 0px rgba(196, 196, 196, 0.05), 11px 113px 113px 0px rgba(196, 196, 196, 0.09), 3px 28px 62px 0px rgba(196, 196, 196, 0.10);
    }

    &__FourthRow {
        display: flex;
        padding: 25px;
        flex-direction: column;
        gap: 10px;
        border-radius: 20px;
        border: 1px solid #C8C8C8;
        background: #FFF;
        box-shadow: 66px 704px 198px 0px rgba(142, 142, 142, 0.00), 42px 451px 181px 0px rgba(196, 196, 196, 0.01), 24px 253px 153px 0px rgba(196, 196, 196, 0.05), 11px 113px 113px 0px rgba(196, 196, 196, 0.09), 3px 28px 62px 0px rgba(196, 196, 196, 0.10);
    }

    &__FifthRow {
        display: flex;
        padding: 25px;
        flex-direction: column;
        gap: 10px;
        border-radius: 20px;
        border: 1px solid #C8C8C8;
        background: #FFF;
        box-shadow: 66px 704px 198px 0px rgba(142, 142, 142, 0.00), 42px 451px 181px 0px rgba(196, 196, 196, 0.01), 24px 253px 153px 0px rgba(196, 196, 196, 0.05), 11px 113px 113px 0px rgba(196, 196, 196, 0.09), 3px 28px 62px 0px rgba(196, 196, 196, 0.10);

        &__LinkedProjects {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__SixthRow {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }
}