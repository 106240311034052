
.chat-container {
  display: flex;
  height: 100%;
  .sidebar {
    width: 29%;
    border-right: 1px solid #ddd;
    padding: 0rem;
    background: #f9f9f9;
    position: sticky;
    top: 0;
    height: 100%;
    overflow-y: auto; 
    z-index: 5;  
    .search-with-message {
      background: #f9f9f9;
      position: sticky;
      top: 0;
      z-index: 10;
      
      h2 {
        margin-top:1rem;
        margin-bottom: 1rem;
        text-align: center;  
      }
      
      .search {
        margin-bottom: 1rem;  
        input {
          // justify-content: center;
          margin: 2px;
          width: 98%;
          padding: 0.8rem; 
          border: 1px solid #ccc;
          border-radius: 0.5rem;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        padding: 1rem; 
        cursor: pointer;
        border-radius: 0.625rem;
        transition: 0.3s;

        &:hover,
        &.active {
          background: #e5e5ff;
        }

        img {
          width: 2.5rem; 
          height: 2.5rem;
          border-radius: 50%;
          margin-right: 1rem;
        }

        .user-info {
          h4 {
            margin: 0;
            font-size: 1rem;
          }

          p {
            margin: 0.2rem 0;
            color: #666;
            font-size: 0.875rem; 
          }
        }
      }
    }
  }

  .chat-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    //  height: 100%;
     position: relative; 
    //  overflow-y: auto;
     min-height: 0; 
 
    height: auto;  

    .chat-header {
      display: flex;
      flex-direction: column; 
      align-items: flex-start;
      padding-bottom: 1rem;
      border-bottom: 1px solid #ddd;
      top: 0;
      background: #ffffff;
      position: sticky;
      z-index: 15;
    
      .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%; 
        gap: 1rem;
      }
    
      h3 {
        margin: 0;
      }
    
      .buttons {
        display: flex;
        gap: 1rem;
    
        button {
          padding: 0.5rem 1rem;
          border: none;
          border-radius: 0.5rem;
          cursor: pointer;
          transition: 0.3s;
        }
    
        .send-proposal {
          background: #6a5acd;
          color: white;
        }
    
        .generate-contract {
          background: linear-gradient(135deg, #a55eea, #8854d0);
          color: white;
        }
    
        button:hover {
          opacity: 0.8;
        }
      }
    
      .dropdown {
        width: 100%;
        margin-top: 10px; 
        .dropdown-button {
          width: 100%;
          padding: 0.75rem;
          font-size: 1rem;
          background: #f6dfef;
          border: 1px solid #ccc;
          border-radius: 0.5rem;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
      
          &:hover {
              background: #ffffff;
          }
      
          &:focus {
              outline: none;
              border-color: #6a5acd;
          }
      }
      
      
      }
    }
    

    .chat-messages {
      flex-grow: 1;
      overflow-y: auto;
      padding: 1rem; 
      display: flex;
      flex-direction: column;

      .message {
        max-width: 60%;
        margin-bottom: 8px;  
        padding:5px 10px; 
        border-radius: 8px; 
        font-size: 0.875rem;  

        &.received {
          background: #e5e5ea;
          align-self: flex-start;
        }

        &.sent {
          background: #6a5acd;
          color: white;
          font-size: 14px;
          font-weight: 500;
          align-self: flex-end;
        }

        .file-message {
          .file {
            background: #6a5acd;
            padding: 1rem;  // Use rem for padding
            border-radius: 0.5rem;  // Use rem for border-radius
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              font-size: 0.75rem;  // Use rem for font-size
              opacity: 0.8;
            }

            .view {
              background: white;
              color: #6a5acd;
              padding: 0.5rem 1rem;  // Use rem for padding
              border-radius: 0.3125rem;  // Use rem
              cursor: pointer;
              border: none;
            }
          }
        }
      }
    }
 
    .chat-input {
      display: flex;
      padding: 1rem;  // Use rem for padding
      background: #ffffff;
      border-top: 1px solid #ddd;
      position: sticky;
      bottom: 0;
      
      input {
        flex-grow: 1;
        padding: 1rem;  // Use rem for padding
        border: 1px solid #ccc;
        border-radius: 0.5rem;
      }

      button {
        background: #6a5acd;
        color: white;
        border: none;
        padding: 1rem;  // Use rem for padding
        margin-left: 1rem;  // Use rem
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }
  }
}

