.FreelancerJobs {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    gap: 20px;

    &__Header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__Title {
            font-size: 40px;
            font-weight: 700;
        }

        &__Actions {
            display: flex;
            align-items: center;
            gap: 35px;
        }
    }

    &__Body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__Filters {
            display: flex;
            align-items: center;
            gap: 35px;
            align-self: stretch;

            &__JobsSegment {
                border-radius: 15px;
                border: 1px solid #D0D0D0;
                background: #FAFAFA;
                .ant-segmented-group {
                    display: flex;
                    padding: 5px;
                    align-items: center;
                    gap: 15px;
                    align-self: stretch;
                }

                .ant-segmented-group .ant-segmented-item {
                    border-radius: 10px;
                    background: #FAFAFA;
                    padding: 10px 15px;
                }

                .ant-segmented-group .ant-segmented-item .ant-segmented-item-label {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: -0.96px;
                }

                .ant-segmented-group .ant-segmented-item-selected {
                    border-radius: 10px;
                    background: #000;
                    padding: 10px 15px;
                }

                .ant-segmented-group .ant-segmented-item-selected .ant-segmented-item-label {
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: -0.96px;
                }
            }

            &__SearchInput {
                padding: 20px;
            }
        }

        &__JobsList {
            border-radius: 12px;
            border: 2px solid #F1F1F1;
            overflow-y: scroll;
            height: calc(100dvh - 276px);
        }
    }

}

.ant-segmented {
    .ant-segmented-item-selected {
        background-color: #000 !important; 
        color: white !important; 
        border-radius: 8px;
    }
    .ant-segmented-item-label{
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 15px;
    }
}
